import { Global } from '@emotion/react'
import { Field, Form, Formik } from 'formik'
import { graphql, useStaticQuery } from 'gatsby'
import compact from 'lodash/compact'
import get from 'lodash/get'
import isObject from 'lodash/isObject'
import mapValues from 'lodash/mapValues'
import startCase from 'lodash/startCase'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import tw, { css, styled } from 'twin.macro'
import { boolean, object, string } from 'yup'
import { usePageContext } from '../context/page-context'
import { ReactComponent as AlertIcon } from '../images/icon-alert.svg'
import { ReactComponent as Close } from '../images/icon-close.svg'
import { encode } from '../utils/form'
import { StyleType } from '../utils/prop-types'
import Button from './button'
import Checkbox from './form-checkbox'
import Select from './form-select'
import TextField from './form-text-field'
import Heading from './heading'
import Text from './text'

const useDemoForm = () => {
  const { en } = useStaticQuery(graphql`
    query {
      en: datoCmsDemoForm(locale: { eq: "en" }) {
        formTitle
        formSubject
        formRequiredLabel
        formFields {
          fieldLabel
          fieldName
          fieldRequired
          fieldRequiredLabel
          fieldType
          fieldOptions
          fieldOptionsNode {
            childMarkdownRemark {
              html
            }
          }
        }
        formButtonLabel
        formSuccessNode {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  `)

  return en
}

const FORM_NAME = 'demo-form'

const getYupSchema = (type) => {
  switch (type) {
    case 'Text':
      return string()
    case 'Email':
      return string()
    case 'Select':
      return object().nullable()
    case 'Checkbox':
      return boolean()
    default:
      return string()
  }
}
const getYupRequired = (schema, type, label) => {
  switch (type) {
    case 'Checkbox':
      return schema.required(label).oneOf([true], label)
    default:
      return schema.required(label)
  }
}

const DemoForm = ({ isOpened, handleClose, style }) => {
  const form = useDemoForm()
  const [success, setSuccess] = useState(null)
  const { originalPath } = usePageContext()

  const schema = useMemo(
    () =>
      object().shape(
        form.formFields.reduce((validation, field) => {
          if (field.fieldRequired)
            return Object.assign(validation, {
              [field.fieldName]: getYupRequired(
                getYupSchema(field.fieldType),
                field.fieldType,
                field.fieldRequiredLabel
              ),
            })
          return validation
        }, {})
      ),
    [form]
  )

  const initialValues = useMemo(
    () =>
      form.formFields.reduce(
        (values, field) => {
          switch (field.fieldType) {
            case 'Text':
              return Object.assign(values, { [field.fieldName]: '' })
            case 'Checkbox':
              return Object.assign(values, { [field.fieldName]: false })
            case 'Select':
              return Object.assign(values, { [field.fieldName]: null })
            default:
              return Object.assign(values, { [field.fieldName]: '' })
          }
        },
        { subject: form.formSubject }
      ),
    [form]
  )

  const FieldGroup = styled.div`
    ${tw`relative`}
  `

  const ErrorMessage = styled.div`
    ${tw`absolute flex items-center mt-2 text-sm text-red-500 leading-caption tracking-caption`}
  `

  return (
    <>
      {isOpened && (
        <Global
          styles={css`
            body {
              ${tw`overflow-hidden`}
            }
          `}
        />
      )}
      <div
        css={[
          tw`transition duration-300 ease-in-out`,
          tw`fixed inset-0 z-30 bg-black bg-opacity-50 opacity-0 pointer-events-none`,
          isOpened && tw`z-30 opacity-100 pointer-events-auto`,
        ]}
        onClick={handleClose}
        aria-hidden="true"
      />
      <aside
        css={[
          tw`overflow-auto transition duration-300 ease-in-out -z-10`,
          tw`fixed top-0 bottom-0 right-0 opacity-0 pointer-events-none bg-secondary-500`,
          isOpened && tw`z-30 opacity-100 pointer-events-auto`,
          style,
        ]}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            setSubmitting(true)

            try {
              const res = await fetch(`/en${originalPath}`, {
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: encode({
                  'form-name': FORM_NAME,
                  ...mapValues(values, (v) => (isObject(v) ? v.label : v)),
                }),
              })
              if (res.status === 200) {
                resetForm()
                setSuccess(form.formSuccessNode)
              } else resetForm()
            } catch (err) {
              console.error(err)
            }
            setSubmitting(false)
          }}
        >
          {({ isSubmitting, errors, touched, setFieldValue, values }) => (
            <>
              <button
                type="button"
                css={css`
                  ${tw`absolute cursor-pointer`}
                  top: 2rem;
                  right: 2.5rem;
                `}
                onClick={handleClose}
              >
                <Close
                  css={tw`lg:(transition-transform duration-300 ease-in-out) lg:hover:(transform rotate-90)`}
                />
              </button>
              {success && (
                <Heading
                  headingType="h4"
                  content={success}
                  style={tw`w-full max-w-modal-demo text-primary-500 font-medium py-16 px-8 lg:(px-16 py-32)`}
                />
              )}
              <Form
                name={FORM_NAME}
                method="post"
                css={[
                  tw`flex flex-col items-start w-full max-w-modal-demo p-8 lg:(px-16 pt-20 pb-42)`,
                  success && tw`hidden`,
                ]}
              >
                <input type="hidden" name="form-name" value={FORM_NAME} />
                <input type="hidden" name="subject" value={form.formSubject} />
                <div css={tw`mb-10`}>
                  <Heading headingType="h3" content={form.formTitle} style={tw`text-primary-500`} />
                  <div css={tw`mt-2 text-sm text-primary-500`}>{form.formRequiredLabel}</div>
                </div>
                <div css={tw`flex flex-col w-full space-y-10`}>
                  {form.formFields.map((field) => {
                    switch (field.fieldType) {
                      case 'Email':
                      case 'Text':
                        return (
                          <FieldGroup key={field.fieldName}>
                            <TextField
                              type="text"
                              name={field.fieldName}
                              label={`${field.fieldLabel}${field.fieldRequired ? '*' : ''}`}
                              invalid={errors[field.fieldName] && touched[field.fieldName]}
                              value={get(values, field.fieldName)}
                              onInput={(v) => setFieldValue(field.fieldName, v.target.value)}
                              aria-required={field.fieldRequired}
                              aria-label={startCase(field.fieldName)}
                            />
                            {errors[field.fieldName] && touched[field.fieldName] ? (
                              <ErrorMessage>
                                <AlertIcon css={tw`mr-2`} />
                                {errors[field.fieldName]}
                              </ErrorMessage>
                            ) : null}
                          </FieldGroup>
                        )
                      case 'Select':
                        return (
                          <FieldGroup key={field.fieldName}>
                            <Field
                              as={Select}
                              key={field.fieldName}
                              name={field.fieldName}
                              placeholder={`${field.fieldLabel}${field.fieldRequired ? '*' : ''}`}
                              options={compact(field.fieldOptions.split('\n')).map((o) => ({
                                label: o,
                                value: o,
                              }))}
                              hasError={errors[field.fieldName] && touched[field.fieldName]}
                              onChange={(v) => setFieldValue(field.fieldName, v)}
                              value={values[field.fieldName]}
                              aria-required={field.fieldRequired}
                              aria-label={startCase(field.fieldName)}
                            />
                            {errors[field.fieldName] && touched[field.fieldName] ? (
                              <ErrorMessage>
                                <AlertIcon css={tw`mr-2`} />
                                {errors[field.fieldName]}
                              </ErrorMessage>
                            ) : null}
                          </FieldGroup>
                        )
                      case 'Checkbox':
                        return (
                          <FieldGroup key={field.fieldName} css={tw`mt-8`}>
                            <Checkbox
                              name={field.fieldName}
                              value={values[field.fieldName]}
                              onChange={(v) => setFieldValue(field.fieldName, v.target.checked)}
                              invalid={errors[field.fieldName] && touched[field.fieldName]}
                              aria-required={field.fieldRequired}
                              aria-label={startCase(field.fieldName)}
                            >
                              <Text
                                style={[
                                  tw`text-base font-light leading-none text-primary-500`,
                                  errors[field.fieldName] &&
                                    touched[field.fieldName] &&
                                    tw`text-red-500`,
                                ]}
                                content={field.fieldOptionsNode}
                              />
                            </Checkbox>
                          </FieldGroup>
                        )
                      default:
                        return null
                    }
                  })}
                  <div css={tw`hidden`}>
                    <input type="text" name="password" placeholder="Password" />
                  </div>
                </div>
                <Button
                  type="primary"
                  size="base"
                  theme="navy-white"
                  buttonType="submit"
                  disabled={isSubmitting}
                  label={form.formButtonLabel}
                  style={tw`mt-14`}
                />
              </Form>
            </>
          )}
        </Formik>
      </aside>
    </>
  )
}

DemoForm.propTypes = {
  isOpened: PropTypes.bool,
  handleClose: PropTypes.func,
  style: StyleType,
}

export default DemoForm
